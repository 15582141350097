export default function useHtmlUtilities(): any {
  function isElementVisible(target: HTMLElement, container: HTMLElement) {
    container = container || document.body;
    const { top, bottom, height } = target.getBoundingClientRect();
    const holderRect = container.getBoundingClientRect();

    return top <= holderRect.top ? holderRect.top - top <= height : bottom - holderRect.bottom <= height;
  }

  return { isElementVisible };
}
